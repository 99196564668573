import React, { useEffect, useState } from "react";
import { BlockContent, BlockDes, BlockHead, BlockTitle, Button, Icon } from "../../../components/Component";
import OTPInput from "react-otp-input";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout, setOtpAttempts, setUserData } from "../../../redux/slices/UserSlice";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { post } from "../../../network/Config/apiService";

function EmailVerification() {
  const dispatch = useDispatch();
  const { user, otp } = useSelector((state) => state.user);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isVerifiyingOTP, setIsVerifiyingOTP] = useState(false);

  const [showResendButton, setShowResendButton] = useState(true);
  const [timer, setTimer] = useState(120);
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [showResendSuccessMessage, setShowResendSuccessMessage] = useState(false);
  const [showResendTimer, setShowResendTimer] = useState(true);

  const verifyOTP = async () => {
    if (enteredOtp.length < 6) {
      setErrorMessage("Please enter a valid OTP");
      setShowMessage(true);
    } else {
      // Verify OTP
      setIsVerifiyingOTP(true);
      post("/auth/verify-otp", {
        email: user?.email,
        otp: Number(enteredOtp),
      })
        .then((response) => {
          if (response.data.result === "success") {
            setIsVerifiyingOTP(false);
            toast.success("OTP verified successfully");
            dispatch(
              setUserData({
                user: {
                  ...user,
                  emailVerified: true,
                },
              })
            );
          }
        })
        .catch((error) => {
          setIsVerifiyingOTP(true);
          setTimeout(() => {
            setIsVerifiyingOTP(false);
            setShowMessage(true);
            setErrorMessage(error.response.data.message || "Failed to verify OTP. Please try again later.");
          }, 2000);
        })
        .finally(() => {
          dispatch(
            setOtpAttempts({
              otp: {
                attemptsLeft: otp.attemptsLeft - 1,
                lastAttempt: new Date().toISOString(),
              },
            })
          );
        });
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  const sendOtpToEmail = async (email) => {
    try {
      const response = await post("/auth/send-otp", {
        email: email,
        name: user?.name,
      });

      if (response.data.result === "success") {
        return true;
      }
    } catch (error) {
      toast.error(error.response.data.message || "Failed to send OTP. Please try again later.");
      return false;
    }
  };

  const handleResendClick = async () => {
    if (otp?.attemptsLeft < 0) return;
    if (isSendingOtp) return;
    if (otp?.attemptsLeft >= 0) {
      setIsSendingOtp(true);
      const isOtpSent = await sendOtpToEmail(user?.email);
      if (isOtpSent === true) {
        setShowResendSuccessMessage(true);
        setTimeout(() => {
          setShowResendSuccessMessage(false);
          setShowResendTimer(true);
          dispatch(
            setOtpAttempts({
              otp: {
                attemptsLeft: otp.attemptsLeft - 1,
                lastAttempt: new Date().toISOString(),
              },
            })
          );
        }, 3000);
      }
      setIsSendingOtp(false);
      setShowResendButton(false);
    }
  };

  useEffect(() => {
    let countdown;
    if (showResendTimer && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [showResendTimer, timer]);

  useEffect(() => {
    if (timer === 0) {
      setShowResendTimer(false);
      setTimer(120);
      setShowResendButton(true);
    }
  }, [timer]);

  useEffect(() => {
    return () => {
      setIsVerifiyingOTP(false);
      setShowMessage(false);
      setErrorMessage("");
      setEnteredOtp("");
      setShowResendButton(true);
      setTimer(120);
      setIsSendingOtp(false);
      setShowResendSuccessMessage(false);
      setShowResendTimer(true);
    };
  }, []);

  return (
    <section>
      {otp.attemptsLeft >= 0 ? (
        <>
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Email OTP Verification</BlockTitle>
              <BlockDes>
                <p>
                  Please enter the OTP sent on <b>{user?.email}</b> to continue.
                </p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <section>
            <OTPInput
              value={enteredOtp}
              onChange={(e) => {
                setEnteredOtp(e.trim());
                setShowMessage(false);
              }}
              onPaste={(e) => {
                e.preventDefault();
                const value = e.clipboardData.getData("text/plain");
                // Remove spaces and slice the first 6 characters
                const otpInput = value.replace(/\s/g, "").slice(0, 6);
                // Check if the value is a number
                if (!isNaN(Number(otpInput))) {
                  setEnteredOtp(otpInput);
                  setShowMessage(false);
                }
              }}
              numInputs={6}
              renderSeparator={<span>&nbsp;&nbsp;</span>}
              renderInput={(props) => <input id="otp" name="otp" {...props} />}
              inputType="number"
              shouldAutoFocus
              inputStyle={{
                width: "100%",
                height: "60px",
                border: `1px solid ${showMessage ? "#FF485C" : "#D9D9D9"}`,
                borderRadius: "6px",
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "21px",
                color: `${showMessage ? "#FF485C" : "var(--text-800)"}`,
                backgroundColor: "var(--base-400)",
              }}
            />
          </section>
          {showMessage && <p className="mt-2 text-danger">{errorMessage}</p>}
          <p className="mt-2">*Did not receive the email? Check your junk/spam folder as well</p>

          {showResendSuccessMessage && <p className="mt-2 text-success">OTP has been sent to your email again</p>}

          <section className="d-flex justify-content-between">
            <Button
              color="white"
              size="lg"
              className="btn btn-white p-0"
              onClick={() => {
                handleResendClick();
              }}
              disabled={isSendingOtp || !showResendButton || otp.attemptsLeft <= 0 || showResendTimer}
            >
              {isSendingOtp
                ? "Sending OTP..."
                : showResendTimer
                ? `Resend OTP in ${formatTime(timer)}`
                : `Resend OTP (${otp?.attemptsLeft} attempts left)`}
            </Button>
            <Button
              color="primary"
              size="lg"
              className="btn btn-primary"
              onClick={() => {
                verifyOTP();
              }}
              disabled={isVerifiyingOTP || enteredOtp.length < 6 || otp.attemptsLeft < 0}
            >
              {isVerifiyingOTP ? <Spinner size="sm" color="light" /> : "Verify OTP"}
            </Button>
          </section>
        </>
      ) : (
        <BlockHead>
          <BlockContent>
            <BlockTitle tag="h4">Heads up! you have exceeded the maximum number of OTP attempts.</BlockTitle>
            <BlockDes>
              <p>
                You have exceeded the maximum number of attempts to verify the OTP. After 1 hour, you can try again. If
                you are facing any issues, please contact us at{" "}
                <a href="mailto:support@navixa.io">
                  <b>support@navixa.io</b>
                </a>
              </p>
            </BlockDes>
          </BlockContent>
        </BlockHead>
      )}

      <button
        className="btn btn-link text-dark"
        style={{
          padding: "5px",
          position: "absolute",
          top: "16px",
          right: "16px",
        }}
        onClick={() => {
          dispatch(logout());
          window.location.href = "/login";
        }}
      >
        <Icon name="cross" />
      </button>
    </section>
  );
}

export default EmailVerification;
