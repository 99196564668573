import React, { useEffect, useState } from "react";
import Logo from "../../../layout/logo/Logo";
import User from "../../../layout/header/dropdown/user/UserSubscription";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle } from "../../../components/Component";
import AppRoot from "../../../layout/global/AppRoot";
import AppMain from "../../../layout/global/AppMain";
import AppWrap from "../../../layout/global/AppWrap";
import Footer from "../../../layout/footer/Footer";
import CartItem from "../../../components/cart/CartItem";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, Spinner } from "reactstrap";
import AuthModel from "../../auth/AuthModel";
import { post } from "../../../network/Config/apiService";
import { toast } from "react-toastify";
import { formatPrice } from "../../../utils/Utils";
import { GetAllPricePlans } from "../../../network/apis/transactions";
import { useDispatch } from "react-redux";
import { addItemToCart } from "../../../redux/slices/CartSlice";

function Cart() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { rtl } = useSelector((state) => state.language);
  const { items } = useSelector((state) => state.cart);
  const [summaryItems, setSummaryItems] = useState([]);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [requestingPaymentLink, setRequestingPaymentLink] = useState(false);
  const [totalPriceInUSD, setTotalPriceInUSD] = useState(0);
  const [totalPriceInIRR, setTotalPriceInIRR] = useState(0);

  const [showLoginModal, setShowLoginModal] = useState(false);

  const [fetchingPlans, setFetchingPlans] = useState(false);

  const calculateSummary = () => {
    const refinedItems = items.map((item) => {
      return {
        title: `${item.title?.length > 20 ? item.title.substring(0, 20) + "..." : item.title} ${
          item?.isRenew ? "(Renew)" : item?.isUpgrade ? "(Upgrade)" : ""
        }`,
        priceInUSD:
          item?.isRenew || item?.isUpgrade
            ? item?.priceInUSD
            : (item?.isBotIncluded ? item?.withBotUsdPrice : item?.withoutBotUsdPrice) * (item?.duration ?? 1),
        priceInIRR:
          item?.isRenew || item?.isUpgrade
            ? item?.priceInIRR
            : (item?.isBotIncluded ? item?.withBotTomansPrice : item?.withoutBotTomansPrice) * (item?.duration ?? 1),
      };
    });

    const totalInUSD = refinedItems.reduce((acc, item) => acc + item.priceInUSD, 0);
    const totalInIRR = refinedItems.reduce((acc, item) => acc + item.priceInIRR, 0);

    setSummaryItems([...refinedItems, { title: "Total", priceInUSD: totalInUSD, priceInIRR: totalInIRR }]);
    setTotalPriceInIRR(totalInIRR);
    setTotalPriceInUSD(totalInUSD);
  };

  const continueToPayment = async () => {
    setRequestingPaymentLink(true);

    post(`/payment/request`, {
      amount: totalPriceInIRR,
      amountToShow: items[0]?.currency === "IRR" ? totalPriceInIRR : totalPriceInUSD,
      description: `${items[0]?.title}`,
      callbackUrl: `${window.location.origin}/pricing`,
      user: {
        uid: user.uid,
        name: user.fullName,
        email: user.email,
        phone: user.phone,
      },
      planData: items,
    })
      .then((response) => {
        setRequestingPaymentLink(false);
        window.location.href = response.data.payment_url;
      })
      .catch((error) => {
        setRequestingPaymentLink(false);
        toast.error(error?.response?.data?.message ?? "An error occurred while processing your request.");
      });
  };

  const getPricingTables = async (planId, isWithBot) => {
    try {
      setFetchingPlans(true);
      const res = await GetAllPricePlans();
      if (res.status === "success") {
        const selectedPlan = res.data.find((plan) => plan.id === planId);

        if (selectedPlan) {
          dispatch(
            addItemToCart({
              item: {
                ...selectedPlan,
                currency: "USD",
                isBotIncluded: selectedPlan?.isBotOptionAvailable && isWithBot ? true : false,
                duration: 1,
              },
            })
          );
        }
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.message ?? "Failed to fetch price plans. Please try again later.");
    } finally {
      setFetchingPlans(false);
    }
  };

  useEffect(() => {
    calculateSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const planId = params.get("planId");
    const withbot = params.get("withbot");

    if (planId) {
      getPricingTables(planId, withbot === "true" ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head title={"Cart"} />
      <AppRoot>
        <AppMain>
          <AppWrap>
            <section className={"nk-header nk-header-fixed is-light"}>
              <div className="container-lg wide-xl">
                <div className="nk-header-wrap">
                  <div className="nk-header-brand">
                    <Logo to={`${process.env.PUBLIC_URL}`} />
                  </div>
                  {user && (
                    <div className={rtl ? "nk-header-tools-right" : "nk-header-tools"}>
                      <ul className="nk-quick-nav">
                        <li className="user-dropdown">
                          <User />
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </section>
            <div className="nk-content">
              <div className="container wide-xl">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <Content>
                      <BlockHead size="lg">
                        <BlockBetween size="md" className="g-4">
                          <BlockHeadContent>
                            <BlockTitle tag="h2" className="fw-normal">
                              Your Cart
                            </BlockTitle>
                          </BlockHeadContent>
                        </BlockBetween>
                      </BlockHead>

                      {fetchingPlans ? (
                        <section className="d-flex justify-content-center align-center pt-5">
                          <Spinner color="primary" />
                        </section>
                      ) : (
                        <>
                          <Block>
                            {items.map((item, index) => (
                              <CartItem key={index} cartItem={item} />
                            ))}
                          </Block>

                          <BlockDes>
                            {items?.length > 0 ? (
                              <>
                                <p>
                                  For more information about our subscription plans, View our subscription plans.
                                  <Link to={`${process.env.PUBLIC_URL}/pricing`}>
                                    <Button color="white" className="mx-2 btn btn-sm btn-outline-primary">
                                      View Subscription Plans
                                    </Button>
                                  </Link>
                                </p>
                              </>
                            ) : (
                              <>
                                <p>
                                  {items?.length > 0
                                    ? "For more information about our subscription plans, View our subscription plans."
                                    : "You don't have selected any subscription plan yet. Please view our subscription plans and select one to continue."}
                                </p>
                                <Link to={`${process.env.PUBLIC_URL}/pricing`}>
                                  <Button color="primary" className="btn btn-primary">
                                    View Subscription Plans
                                  </Button>
                                </Link>
                              </>
                            )}
                          </BlockDes>
                          {items?.length > 0 && (
                            <Block>
                              <section className="cart-summary">
                                {summaryItems.map((item, index) => (
                                  <div key={index} className="cart-summary-item">
                                    <p className="cart-summary-item-title">{item.title}</p>
                                    <p className="cart-summary-item-price">
                                      {formatPrice({
                                        currency: items[0]?.currency,
                                        amount: items[0]?.currency === "IRR" ? item.priceInIRR : item.priceInUSD,
                                      })}
                                    </p>
                                  </div>
                                ))}

                                <div className="form mt-4 mx-2">
                                  <div className="form-group">
                                    <label
                                      className="form-label d-flex align-items-center gap-1"
                                      htmlFor="confirmTerms"
                                    >
                                      <input
                                        type="checkbox"
                                        name="confirmTerms"
                                        id="confirmTerms"
                                        style={{
                                          width: "16px",
                                          height: "16px",
                                          cursor: "pointer",
                                        }}
                                        checked={acceptedTerms}
                                        onChange={(e) => setAcceptedTerms(e.target.checked)}
                                      />
                                      <span
                                        className="ml-2"
                                        style={{
                                          userSelect: "none",
                                        }}
                                      >
                                        I agree to the{" "}
                                        <a
                                          href="https://navixa.io/terms-and-conditions"
                                          target="_blank"
                                          rel="noreferrer"
                                          style={{
                                            marginLeft: "2px",
                                          }}
                                        >
                                          terms and conditions.
                                        </a>
                                      </span>
                                    </label>
                                  </div>
                                </div>

                                <Button
                                  color="primary"
                                  className="btn btn-primary btn-lg w-100 justify-content-center mt-4"
                                  disabled={!acceptedTerms || requestingPaymentLink || items.length === 0}
                                  onClick={() => {
                                    if (user && user?.emailVerified) {
                                      continueToPayment();
                                    } else {
                                      setShowLoginModal(true);
                                    }
                                  }}
                                >
                                  {requestingPaymentLink ? (
                                    <Spinner size="sm" color="white" />
                                  ) : user && user?.emailVerified ? (
                                    "Continue to Payment"
                                  ) : (
                                    "Continue"
                                  )}
                                </Button>
                              </section>
                            </Block>
                          )}
                        </>
                      )}
                    </Content>

                    <Modal isOpen={showLoginModal} size="md" toggle={() => setShowLoginModal(false)}>
                      <button
                        onClick={(ev) => {
                          ev.preventDefault();
                          setShowLoginModal(false);
                        }}
                        className="close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </button>
                      <ModalBody className="modal-body-md">
                        <AuthModel />
                      </ModalBody>
                    </Modal>

                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </AppWrap>
        </AppMain>
      </AppRoot>
    </>
  );
}

export default Cart;
